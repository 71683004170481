import React, { useCallback, useContext, useState } from "react"
import Grid from "@material-ui/core/Grid"
import { Helmet } from "react-helmet"

import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { BlogContext, BlogContextActions } from "../components/blog-context"
import BlogList from "../components/blog-list"
import BlogLoadMore from "../components/blog-load-more"

export default function Index() {
  const buildData = useStaticQuery(graphql`
    query {
      allBlog(sort: { fields: SK, order: DESC }) {
        nodes {
          SK
          title
          blurb
          path
          tags {
            items {
              tagPK
            }
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { nodes: buildBlogs } = buildData.allBlog
  const { title, description } = buildData.site.siteMetadata

  const { state, dispatch } = useContext(BlogContext)

  const [blogShowCount, setBlogShowCount] = useState<number>(state.blogListShow)

  const clickLoadMore = useCallback(() => {
    setBlogShowCount(prev => {
      const show = prev + 10
      dispatch({
        type: BlogContextActions.UPDATE_BLOG_LIST_SHOW,
        payload: {
          count: show,
        },
      })
      return show
    })
  }, [dispatch])

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Grid container>
        <BlogList blogs={buildBlogs.slice(0, blogShowCount)} />
        <BlogLoadMore
          show={blogShowCount < buildBlogs.length}
          clickHandler={clickLoadMore}
        />
      </Grid>
    </React.Fragment>
  )
}
